import { nodeInstance } from "@/plugins/axios";
import { db } from "@/plugins/firebase";
const networkingTeacherDb = db.collection("networkingTeacher");

const networkingUsersTeacherServices = {};

networkingUsersTeacherServices.createdNetworkingUsersTeacher = networkingUsers => {
    return nodeInstance.post(`/api/networkingUserTeacher`, networkingUsers);
};

networkingUsersTeacherServices.infoNetworkingUsersTeacher = (networkingId) => {
    return nodeInstance.get(`/api/networkingUserTeacher/${networkingId}`);
};

networkingUsersTeacherServices.getNetworkingUsersTeacher = (networkingTeacherId, limit) => {
    return networkingTeacherDb.where("networkingTeacherId", "==", networkingTeacherId).limit(limit);
};

export default networkingUsersTeacherServices;
