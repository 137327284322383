<template src="./Meet.html"></template>

<script>
import { mapGetters } from "vuex";
import networkingService from "@/services/Networking";
import configService from "@/configs/config";
import networkingUsersService from "@/services/NetworkingUsers";
import { db } from "@/plugins/firebase";
const surveyTeacherDb = db.collection("considerNetworkingTeacher");
const surveyRoomDb = db.collection("considerNetworking");
const gameTeacherDb = db.collection("evaluationNetworkingTeacher");
const gameRoomDb = db.collection("evaluationNetworking");
import firebase from "firebase";
import { required } from "vuelidate/lib/validators";
import networkingUsersTeacherServices from "../../services/NetworkingUsersTeacher";

export default {
  name: "Meet",
  data() {
    return {
      api: [],
      networking: {},
      showVideoText: "Activar Camara",
      showAudioText: "Activar Microfono",
      shareScreenText: "Compartir Pantalla",
      showProfileText: "Ocultar lista de ingreso en sala",
      userInfo: {},
      getParticipantsInfo: [],
      isModerator: false,
      networkingUsers: [],
      followsRequest: [],
      intervalMeet: null,
      columnOne: "column is-8",
      columnTwo: "column is-4",
      moderatorInfo: false,
      surveyTime: [
        { id: 60000, duration: "1 minuto" },
        { id: 120000, duration: "2 minutos" },
        { id: 180000, duration: "3 minutos" },
      ],
      gameTime: [
        { id: 60000, duration: "1 minuto" },
        { id: 120000, duration: "2 minutos" },
        { id: 180000, duration: "3 minutos" },
      ],
      sectionCreateSurvey: true,
      sectionSurveyresult: false,
      createQuestionsSection: true,
      sectionGameResult: false,
      surveyInfo: {
        question: "",
        answersOne: "",
        answersTwo: "",
        answersThree: "",
        answersFour: "",
        duration: 60000,
      },
      gameInfo: {
        question: "",
        answersOne: "",
        answersTwo: "",
        answersThree: "",
        answersFour: "",
        duration: 60000,
        response: [{ correct: false }],
      },
      isPrivateRoom: false,
      modalIsPrivateRoom: true,
      copyMeetingUrl: "",
      modalSurveyResponses: false,
      modalGameResponses: false,
      surveyResponses: {},
      gameResponses: [],
      infoConsultPrivateRoom: {},
      isUrl: true,
      userAddedRoom: {
        networkingTeacher: { id: 0, aforo: 0 },
        user: {},
      },
      infoSurvey: {},
      infoGame: {},
      infoPrivateRoomSurveyResults: [
        { considerNetworkingTeacher: { answers: "" } },
      ],
      infoPrivateRoomGameResults: [
        { evaluationNetworkingTeacher: { answers: "" } },
      ],
      infoRoomSurveyResults: [{ considerNetworking: { answers: "" } }],
      infoRoomGameResults: [{ evaluationNetworking: { answers: "" } }],
      arrayResultSurvey: [{ votes: 0 }],
      arrayResultGame: [{ votes: 0 }],
      enableButton: false,
      activeTab: 1,
      timer: 0,
      minutes: 0,
      seconds: 0,
      timerGame: 0,
      minutesGame: 0,
      secondsGame: 0,
      sectionSurveyTime: false,
      sectionGameTime: false,
      isActive: "survey",
      correctAnswersOne: false,
      correctAnswersTwo: false,
      correctAnswersThree: false,
      correctAnswersFour: false,
      modalGameFeedback: false,
      arrayCorrectAnswers: [],
    };
  },
  validations() {
    const infoSurvey = {
      surveyInfo: {
        question: { required },
        answersOne: { required },
        answersTwo: { required },
        duration: { required },
      },
    };
    const infoGame = {
      gameInfo: {
        question: { required },
        answersOne: { required },
        answersTwo: { required },
        duration: { required },
        activeStart: { required },
      },
    };
    if (this.isActive === "survey") {
      return infoSurvey;
    }
    if (this.isActive === "gameQuestion") {
      return infoGame;
    }
  },
  async created() {
    try {
      this.copyMeetingUrl = window.location;
      this.isPrivateRoom = this.$route.params.isPrivateRoom;
      this.isUrl = this.$route.params.isUrl;
      if (this.isUrl === undefined) {
        this.isUrl = true;
      }
      if (this.isUrl) {
        this.saveNetworkingUsersTeacher();
      }
      if (!this.isPrivateRoom) {
        this.infoPrivateRoom();
      }
      const numberOfParticipants = await this.api.getNumberOfParticipants();
      const getParticipantsInfo = await this.api.getParticipantsInfo();
      this.getParticipantsInfo = getParticipantsInfo;
      if (numberOfParticipants !== this.networking.person) {
        await this.changeNumberParticipants(numberOfParticipants);
      }
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    this.getInformation();
    this.initSurvey();
    this.initGame();
  },
  methods: {
    async getInformation() {
      try {
        await this.getNetworkig();
        if (!this.isUrl) {
          await this.validateUserParticipant();
          await this.getNetworkigUsers();
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    async getNetworkig() {
      if (this.isPrivateRoom) {
        this.infoConsultPrivateRoom =
          await networkingService.consultPrivateRoom();
        await this.getNetworkigUsersTeachers();
      } else if (this.isPrivateRoom || !this.isUrl) {
        this.networking = await networkingService.getNetworking(
          this.$route.params.idNetworking
        );
      }
    },
    async validateUserParticipant() {
      if (this.isPrivateRoom) {
        this.validateModerador();
        this.createdJetsi(this.infoConsultPrivateRoom.networkingTeacher);
      } else {
        const participant = {
          networkingId: this.$route.params.idNetworking,
        };
        const userParticipant =
          await networkingUsersService.userNetworkingParticipant(participant);
        if (userParticipant.participant) {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "¡Ingresando a Networking!",
            type: "is-success",
          });
          this.validateModerador();
          this.createdJetsi(this.networking);
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "El participante no tiene acceso!",
            type: "is-danger",
          });
          setTimeout(() => {
            this.$router.go(-1);
          }, 2000);
        }
      }
    },
    async getNetworkigUsers() {
      const { id, aforo } = this.networking;
      networkingUsersService
        .getNetworkingUsers(id, aforo)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              this.networkingUsers.push({
                ...change.doc.data(),
                id: change.doc.id,
              });
            }
            if (change.type === "removed") {
              this.networkingUsers = this.networkingUsers.filter(
                (user) => user.id !== change.doc.id
              );
              this.$router.push({ name: "Networking" });
            }
          });
        });
    },
    async getNetworkigUsersTeachers() {
      if (this.isUrl) {
        const { id, aforo } = this.userAddedRoom.networkingTeacher;
        networkingUsersTeacherServices
          .getNetworkingUsersTeacher(id, aforo)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                this.networkingUsers.push({
                  ...change.doc.data(),
                  id: change.doc.id,
                });
              }
              if (change.type === "removed") {
                this.networkingUsers = this.networkingUsers.filter(
                  (user) => user.id !== change.doc.id
                );
              }
            });
          });
      }
      if (this.isPrivateRoom) {
        const { id, aforo } = this.infoConsultPrivateRoom.networkingTeacher;
        networkingUsersTeacherServices
          .getNetworkingUsersTeacher(id, aforo)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                this.networkingUsers.push({
                  ...change.doc.data(),
                  id: change.doc.id,
                });
              }
              if (change.type === "removed") {
                this.networkingUsers = this.networkingUsers.filter(
                  (user) => user.id !== change.doc.id
                );
              }
            });
          });
      }
    },
    validateModerador() {
      if (this.isPrivateRoom) {
        if (
          this.infoConsultPrivateRoom.networkingTeacher.moderator.id ===
          this.user.id
        ) {
          this.isModerator = true;
        }
      } else if (this.user.id == this.networking.userId) {
        this.isModerator = true;
        this.moderatorInfo = true;
      }
    },
    createdJetsi(networking) {
      const domain = configService.apiJitsi;
      const options = {
        roomName: networking.meetUrl,
        height: 700,
        configOverwrite: {
          startWithAudioMuted: true,
          startWithVideoMuted: true,
        },
        interfaceConfigOverwrite: { DISABLE_DOMINANT_SPEAKER_INDICATOR: true },
        parentNode: document.querySelector("#meet"),
        userInfo: {
          displayName: this.user.name,
        },
      };
      const api = new JitsiMeetExternalAPI(domain, options);
      this.api = api;
    },
    redirectProfileUser(follow) {
      if (follow === this.$store.state.User.user.id) {
        let routeData = this.$router.resolve({
          name: "Profile",
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: "ProfileUsers",
          params: { id: follow },
        });
        window.open(routeData.href, "_blank");
      }
    },
    async changeNumberParticipants(numberOfParticipants) {
      const networking = {
        id: this.networking.id,
        category: parseInt(
          this.networking.category
            ? this.networking.category
            : this.networking.categoryId
        ),
        name: this.networking.name,
        person: numberOfParticipants,
        description: this.networking.description,
      };
      this.networking = await networkingService.changeNetworkings(networking);
    },
    buttonshowAudio(showAudioText) {
      if (showAudioText === "Activar Microfono") {
        this.api.executeCommand("toggleAudio");
        this.showAudioText = "Desactivar Microfono";
      } else if (showAudioText === "Desactivar Microfono") {
        this.api.executeCommand("toggleAudio");
        this.showAudioText = "Activar Microfono";
      }
    },
    buttonShareScreen(shareScreenText) {
      if (shareScreenText === "Compartir Pantalla") {
        this.api.executeCommand("toggleShareScreen");
        this.shareScreenText = "Quitar Pantalla";
      } else if (shareScreenText === "Quitar Pantalla") {
        this.api.executeCommand("toggleShareScreen");
        this.shareScreenText = "Compartir Pantalla";
      }
    },
    buttonShowProfiles(showProfileText) {
      if (showProfileText === "Ocultar lista de ingreso en sala") {
        this.columnOne = "column is-12";
        this.columnTwo = "column is-0";
        this.showProfileText = "Mostrar lista de ingreso en sala";
      } else if (showProfileText === "Mostrar lista de ingreso en sala") {
        this.columnOne = "column is-8";
        this.columnTwo = "column is-4";
        this.showProfileText = "Ocultar lista de ingreso en sala";
      }
    },
    buttonMuteEveryone() {
      this.api.executeCommand("muteEveryone");
      this.showAudioText = "Activar Microfono";
    },
    buttonshowVideo(showVideoText) {
      if (showVideoText === "Desactivar Camara") {
        this.api.executeCommand("toggleVideo");
        this.showVideoText = "Activar Camara";
      } else if (showVideoText === "Activar Camara") {
        this.api.executeCommand("toggleVideo");
        this.showVideoText = "Desactivar Camara";
      }
    },
    async finishEvent() {
      const getParticipantsInfo = this.api.getParticipantsInfo();
      for (const getParticipantInfo of getParticipantsInfo) {
        this.api.executeCommand(
          "kickParticipant",
          String(getParticipantInfo.participantId)
        );
      }
      const numberOfParticipants = this.api.getNumberOfParticipants();
      if (numberOfParticipants === 1 || numberOfParticipants === 0) {
        const result = await networkingUsersService.deleteNetworkingUsers(
          this.networking.id
        );
        if (result) {
          clearInterval(this.intervalMeet);
          this.$router.push({ name: "Networking" });
        }
      }
    },
    async StartSurvey() {
      try {
        const answers = [];
        if (this.surveyInfo.answersOne) {
          answers.push({
            option: this.surveyInfo.answersOne,
          });
        }
        if (this.surveyInfo.answersTwo) {
          answers.push({
            option: this.surveyInfo.answersTwo,
          });
        }
        if (this.surveyInfo.answersThree) {
          answers.push({
            option: this.surveyInfo.answersThree,
          });
        }
        if (this.surveyInfo.answersFour) {
          answers.push({
            option: this.surveyInfo.answersFour,
          });
        }
        if (!this.isPrivateRoom) {
          const obj = {
            question: this.surveyInfo.question,
            answers: answers,
            duration: this.surveyInfo.duration,
            networkingId: this.$route.params.idNetworking,
          };
          this.infoSurvey = await networkingService.createRoomSurvey(obj);
        } else {
          const objTeacher = {
            question: this.surveyInfo.question,
            answers: answers,
            duration: this.surveyInfo.duration,
            networkingTeacherId:
              this.infoConsultPrivateRoom.networkingTeacher.id,
          };
          this.infoSurvey = await networkingService.createSurveyTeacher(
            objTeacher
          );
        }
        this.sectionSurveyTime = true;
        this.sectionCreateSurvey = false;
      } catch (error) {
        console.error("error", error);
      }
    },
    startNewSurvey() {
      this.sectionSurveyTime = false;
      this.sectionSurveyresult = false;
      this.sectionCreateSurvey = true;
      this.surveyInfo = { duration: 60000 };
      this.arrayResultSurvey = [{ votes: 0 }];
      this.enableButton = false;
      this.surveyResponses = {};
    },
    startNewGame() {
      this.sectionGameTime = false;
      this.sectionGameResult = false;
      this.createQuestionsSection = true;
      this.gameInfo = { duration: 60000 };
      this.arrayResultGame = [{ votes: 0 }];
      this.gameResponses = [];
      this.enableButton = false;
      this.correctAnswersOne = false;
      this.correctAnswersTwo = false;
      this.correctAnswersThree = false;
      this.correctAnswersFour = false;
    },
    async StartGame() {
      try {
        const answersGame = [];
        if (this.gameInfo.answersOne) {
          answersGame.push({
            option: this.gameInfo.answersOne,
            correct: this.correctAnswersOne,
          });
        }
        if (this.gameInfo.answersTwo) {
          answersGame.push({
            option: this.gameInfo.answersTwo,
            correct: this.correctAnswersTwo,
          });
        }
        if (this.gameInfo.answersThree) {
          answersGame.push({
            option: this.gameInfo.answersThree,
            correct: this.correctAnswersThree,
          });
        }
        if (this.gameInfo.answersFour) {
          answersGame.push({
            option: this.gameInfo.answersFour,
            correct: this.correctAnswersFour,
          });
        }
        if (!this.isPrivateRoom) {
          const obj = {
            question: this.gameInfo.question,
            answers: answersGame,
            duration: this.gameInfo.duration,
            networkingId: this.$route.params.idNetworking,
          };
          this.infoGame = await networkingService.createRoomGame(obj);
        } else {
          const objTeacher = {
            question: this.gameInfo.question,
            answers: answersGame,
            duration: this.gameInfo.duration,
            networkingTeacherId:
              this.infoConsultPrivateRoom.networkingTeacher.id,
          };
          this.infoGame = await networkingService.createRoomGameTeacher(
            objTeacher
          );
        }
        this.sectionGameTime = true;
        this.createQuestionsSection = false;
      } catch (error) {
        console.error("error", error);
      }
    },
    infoPrivateRoom() {
      this.modalIsPrivateRoom = false;
    },
    copyUrl(url) {
      try {
        navigator.clipboard.writeText(url);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se copió la URL correctamente",
          type: "is-success",
        });
      } catch (err) {
        console.log(err);
        this.$swal({
          text: err.message || "Ocurrio un error, intenta mas tarde.",
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    },
    async initSurvey() {
      const dateEnter = firebase.firestore.Timestamp.now();
      if (this.isPrivateRoom || this.isUrl) {
        surveyTeacherDb
          .where(
            "networkingTeacherId",
            "==",
            Number(this.$route.params.idNetworking)
          )
          .where("createdSurvey", ">=", dateEnter)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  if (!this.isModerator) {
                    this.modalSurveyResponses = true;
                  }
                  this.surveyInfo = change.doc.data();
                  setTimeout(() => {
                    this.modalSurveyResponses = false;
                    this.enableButton = true;
                    this.privateRoomSurveyResults(
                      this.surveyInfo.considerNetworkingTeacherId
                    );
                  }, this.surveyInfo.duration);
                  this.countDownTimer();
                  break;
              }
            });
          });
      } else {
        surveyRoomDb
          .where("networkingId", "==", Number(this.$route.params.idNetworking))
          .where("createdSurvey", ">=", dateEnter)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  if (!this.isModerator) {
                    this.modalSurveyResponses = true;
                  }
                  this.surveyInfo = change.doc.data();
                  setTimeout(() => {
                    this.modalSurveyResponses = false;
                    this.enableButton = true;
                    this.roomSurveyResults(
                      this.surveyInfo.considerNetworkingId
                    );
                  }, this.surveyInfo.duration);
                  this.countDownTimer();
                  break;
              }
            });
          });
      }
    },
    async initGame() {
      const dateEnter = firebase.firestore.Timestamp.now();
      if (this.isPrivateRoom || this.isUrl) {
        gameTeacherDb
          .where(
            "networkingTeacherId",
            "==",
            Number(this.$route.params.idNetworking)
          )
          .where("createdSurvey", ">=", dateEnter)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  if (!this.isModerator) {
                    this.modalGameResponses = true;
                  }
                  this.gameInfo = change.doc.data();
                  setTimeout(() => {
                    this.modalGameResponses = false;
                    this.createArrayFeedback();
                    if (!this.isModerator) {
                      this.modalGameFeedback = true;
                    }
                    this.enableButton = true;
                    this.privateRoomGameResults(
                      this.gameInfo.evaluationNetworkingTeacherId
                    );
                  }, this.gameInfo.duration);
                  this.countDownTimerGame();
                  break;
              }
            });
          });
      } else {
        gameRoomDb
          .where("networkingId", "==", Number(this.$route.params.idNetworking))
          .where("createdSurvey", ">=", dateEnter)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  if (!this.isModerator) {
                    this.modalGameResponses = true;
                  }
                  this.gameInfo = change.doc.data();
                  setTimeout(() => {
                    this.modalGameResponses = false;
                    this.createArrayFeedback();
                    if (!this.isModerator) {
                      this.modalGameFeedback = true;
                    }
                    this.enableButton = true;
                    this.roomGameResults(this.gameInfo.evaluationNetworkingId);
                  }, this.gameInfo.duration);
                  this.countDownTimerGame();
                  break;
              }
            });
          });
      }
    },
    async saveNetworkingUsersTeacher() {
      try {
        this.userAddedRoom =
          await networkingUsersTeacherServices.createdNetworkingUsersTeacher({
            networkingId: this.$route.params.idNetworking,
          });
        await this.getNetworkigUsersTeachers();
        if (this.userAddedRoom) {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "¡Ingresando a la sala con éxito!",
            type: "is-success",
          });
          this.createdJetsi(this.userAddedRoom.networkingTeacher);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async votePoll(id, response) {
      try {
        if (!this.isPrivateRoom && !this.isUrl) {
          const obj = {
            response,
            considerNetworkingId: id,
          };
          await networkingService.createResponseRoomSurvey(obj);
        } else {
          const objTeacher = {
            response,
            considerNetworkingTeacherId: id,
          };
          await networkingService.createResponseSurveyTeacher(objTeacher);
        }
        this.modalSurveyResponses = false;
      } catch (error) {
        console.log(error);
      }
    },
    async voteQuestion(id, response) {
      try {
        if (!this.isPrivateRoom && !this.isUrl) {
          const obj = {
            response,
            evaluationNetworkingId: id,
          };
          await networkingService.createResponseRoomGame(obj);
        } else {
          const objTeacher = {
            response,
            evaluationNetworkingTeacherId: id,
          };
          await networkingService.createResponseGameTeacher(objTeacher);
        }
        this.modalGameResponses = false;
        this.gameResponses = [];
      } catch (error) {
        console.log(error);
      }
    },
    async privateRoomSurveyResults(id) {
      try {
        this.infoPrivateRoomSurveyResults =
          await networkingService.getResultSurveyTeacher(id);
        this.arrayResultSurvey = JSON.parse(
          this.infoPrivateRoomSurveyResults[0].considerNetworkingTeacher.answers
        );
      } catch (error) {
        console.log(error);
      }
    },
    async privateRoomGameResults(id) {
      try {
        this.infoRoomGameResults = await networkingService.getResultGameTeacher(
          id
        );
      } catch (error) {
        console.log(error);
      }
    },
    async roomSurveyResults(id) {
      try {
        this.infoRoomSurveyResults =
          await networkingService.getResultRoomSurvey(id);
        this.arrayResultSurvey = JSON.parse(
          this.infoRoomSurveyResults[0].considerNetworking.answers
        );
      } catch (error) {
        console.log(error);
      }
    },
    async roomGameResults(id) {
      try {
        this.infoRoomGameResults = await networkingService.getResultRoomGame(
          id
        );
      } catch (error) {
        console.log(error);
      }
    },
    countDownTimer() {
      this.timer = this.surveyInfo.duration;
      let interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(interval);
        } else {
          this.timer -= 1000;
          return milisegundosAMinutosYSegundos(this.timer);
        }
      }, 1000);
      const milisegundosAMinutosYSegundos = (milisegundos) => {
        this.minutes = parseInt(milisegundos / 1000 / 60);
        milisegundos -= this.minutes * 60 * 1000;
        this.seconds = milisegundos / 1000;
        if (this.minutes === 0 && this.seconds === 0) {
          this.sectionSurveyresult = true;
          this.sectionSurveyTime = false;
        }
        return `${this.minutes}:${this.seconds}`;
      };
    },
    countDownTimerGame() {
      this.timerGame = this.gameInfo.duration;
      let interval = setInterval(() => {
        if (this.timerGame === 0) {
          clearInterval(interval);
        } else {
          this.timerGame -= 1000;
          return milisegundosAMinutosYSegundos(this.timerGame);
        }
      }, 1000);
      const milisegundosAMinutosYSegundos = (milisegundos) => {
        this.minutesGame = parseInt(milisegundos / 1000 / 60);
        milisegundos -= this.minutesGame * 60 * 1000;
        this.secondsGame = milisegundos / 1000;
        if (this.minutesGame === 0 && this.secondsGame === 0) {
          this.sectionGameResult = true;
          this.sectionGameTime = false;
        }
        return `${this.minutesGame}:${this.secondsGame}`;
      };
    },
    createArrayFeedback() {
      try {
        if (this.gameInfo.responses[0].correct === true) {
          this.arrayCorrectAnswers.push(this.gameInfo.responses[0].question);
        }
        if (this.gameInfo.responses[1].correct === true) {
          this.arrayCorrectAnswers.push(this.gameInfo.responses[1].question);
        }
        if (this.gameInfo.responses[2].correct === true) {
          this.arrayCorrectAnswers.push(this.gameInfo.responses[2].question);
        }
        if (this.gameInfo.responses[3].correct === true) {
          this.arrayCorrectAnswers.push(this.gameInfo.responses[3].question);
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    closeModalFeedback() {
      this.modalGameFeedback = false;
      this.arrayCorrectAnswers = [];
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    startContinue() {
      if (
        this.correctAnswersOne &&
        this.gameInfo.question &&
        this.gameInfo.answersOne &&
        this.gameInfo.answersTwo &&
        this.gameInfo.duration
      ) {
        return true;
      }
      if (
        this.correctAnswersTwo &&
        this.gameInfo.question &&
        this.gameInfo.answersOne &&
        this.gameInfo.answersTwo &&
        this.gameInfo.duration
      ) {
        return true;
      }
      if (
        this.correctAnswersThree &&
        this.gameInfo.question &&
        this.gameInfo.answersOne &&
        this.gameInfo.answersTwo &&
        this.gameInfo.duration
      ) {
        return true;
      }
      if (
        this.correctAnswersFour &&
        this.gameInfo.question &&
        this.gameInfo.answersOne &&
        this.gameInfo.answersTwo &&
        this.gameInfo.duration
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" src="./Meet.scss" scoped></style>
