import { nodeInstance } from "@/plugins/axios";

const networkingService = {};

networkingService.getNetworking = id => {
  const params = {
    id: id
  };
  return nodeInstance.get(`/api/networking`, { params });
};

networkingService.searchNetworking = name => {
  return nodeInstance.post("/api/networking/search", { name });
};

networkingService.getNetworkings = () => {
  return nodeInstance.get(`/api/networkings`);
};

networkingService.changeNetworkings = networking => {
  return nodeInstance.patch(`/api/networking/update`, networking);
};

networkingService.getNetworkingsAdmin = () => {
  return nodeInstance.get(`/api/admin/networking`);
};

networkingService.changeNetworkingsAdmin = networking => {
  return nodeInstance.patch(`/api/admin/networking`, networking);
};

networkingService.createNetworking = data => {
  return nodeInstance.post("/api/networking/create", data);
};

networkingService.consultPrivateRoom = () => {
  return nodeInstance.get("/api/networkingTeacher");
};

networkingService.createSurveyTeacher = data => {
  return nodeInstance.post("/api/considerNetworkingTeacher", data);
};

networkingService.createRoomGameTeacher = data => {
  return nodeInstance.post("/api/evaluationNetworkingTeacher", data);
};

networkingService.createResponseSurveyTeacher = data => {
  return nodeInstance.post("/api/responseConsiderNetworkingTeacher", data);
};

networkingService.createResponseGameTeacher = data => {
  return nodeInstance.post("/api/responseEvaluationNetworkingTeacher", data);
};

networkingService.getResultSurveyTeacher = (considerNetworkingTeacherId) => {
  return nodeInstance.get(`/api/responseConsiderNetworkingTeacher/byConsiderNetworkingTeacher/${considerNetworkingTeacherId}`);
}

networkingService.getResultGameTeacher = (considerNetworkingTeacherId) => {
  return nodeInstance.get(`/api/evaluationNetworkingTeacher/winner/${considerNetworkingTeacherId}`);
}

networkingService.createRoomSurvey = data => {
  return nodeInstance.post("/api/considerNetworking", data);
};

networkingService.createRoomGame = data => {
  return nodeInstance.post("/api/evaluationNetworking", data);
};

networkingService.createResponseRoomSurvey = data => {
  return nodeInstance.post("/api/responseConsiderNetworking", data);
};

networkingService.createResponseRoomGame = data => {
  return nodeInstance.post("/api/responseEvaluationNetworking", data);
};

networkingService.getResultRoomSurvey = (considerNetworkingId) => {
  return nodeInstance.get(`/api/responseConsiderNetworking/byConsiderNetworking/${considerNetworkingId}`);
}

networkingService.getResultRoomGame = (considerNetworkingId) => {
  return nodeInstance.get(`/api/evaluationNetworking/winner/${considerNetworkingId}`);
}

export default networkingService;
