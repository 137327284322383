import { nodeInstance } from "@/plugins/axios";
import { db } from "@/plugins/firebase";
const networkingDb = db.collection("networking");

const networkingUsersService = {};

networkingUsersService.createdNetworkingUsers = networkingUsers => {
  return nodeInstance.post(`/api/networking/user`, networkingUsers);
};

networkingUsersService.updatedNetworkingUsers = networkingUsers => {
  return nodeInstance.patch(`/api/networking/user`, networkingUsers);
};

networkingUsersService.userNetworkingParticipant = networkingUsers => {
  return nodeInstance.post(`/api/networking/user/participant`, networkingUsers);
};
networkingUsersService.deleteNetworkingUsers = idNetworking => {
  const params = {
    id: idNetworking
  };
  return nodeInstance.delete(`/api/networking/`, { params });
};

networkingUsersService.getNetworkingUsers = (networkingId, limit) => {
  return networkingDb.where("networkingId", "==", networkingId).limit(limit);
};

export default networkingUsersService;
